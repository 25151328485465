.mainFooter {
  z-index: 2;
  text-align: center;
  padding: 0em 2em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 6em;;
  background-color: rgb(255, 255, 255);
  transform: translate(-50%, 0%);

  .button {
    display: inline-block;
    position: relative;
  }

  .icon {
    display: inline-block;
    color: #1f1f1f;
    background-color: var(--backgroundColor0);
    border: 1px solid #1f1f1f;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    margin: 0 0.6em;
    i {
      text-align: center;
      font-size: 1.5em;
      line-height: calc(2em - 2px);
    }
  }

  @media (hover) {
    .icon {
      &:hover {
        color: var(--backgroundColor0);
        background-color: #1f1f1f;
        border-color: #1f1f1f;
        transform: scale(1.2);
      }
    }
  }

  @media (min-width: 270px) and (max-width: 600px) {
    height: auto;
    padding-bottom: 1em;
    .button {
      order: -2;
      flex-basis: 100%;
    }
    .icon {
      border: 0;
      width: 2.5em;
      height: 2.5em;
      margin: 0 0.3em;

      i {
        line-height: 1.67em;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1023px) {
    padding-bottom: 1em;
    padding-top: 1em;
    .button {
      order: 0;
      flex-basis: auto;
    }

    .icon {
      border: 1px solid #1f1f1f;
      border-radius: 50%;
      width: 3em;
      height: 3em;

      i {
        font-size: 1.5em;
        line-height: calc(2em - 2px);
      }
    }
  }
}
