.portfolio {
  $hoverEasing: cubic-bezier(0.23, 1, 0.32, 1);
  $returnEasing: cubic-bezier(0.445, 0.05, 0.55, 0.95);

  .title {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 700;
    color: #5d4037;
    text-align: center;
  }

  p {
    line-height: 1.5em;
  }

  h1 + p,
  p + p {
    margin-top: 10px;
  }

  .portfolioContainer {
    // padding: 40px 80px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-wrap {
    margin: 10px;
    transform: perspective(800px);
    transform-style: preserve-3d;
    cursor: pointer;
    // background-color: #fff;

    &:hover {
      .card-info {
        transform: translateY(0);
      }
      .card-info p {
        opacity: 1;
      }
      .card-info,
      .card-info p {
        transition: 0.6s $hoverEasing;
      }
      .card-info:after {
        transition: 5s $hoverEasing;
        opacity: 1;
        transform: translateY(0);
      }
      .card-bg {
        transition: 0.6s $hoverEasing, opacity 5s $hoverEasing;
        opacity: 0.8;
      }
      .card {
        transition: 0.6s $hoverEasing, box-shadow 2s $hoverEasing;
        box-shadow: rgba(rgb(255, 255, 255), 0.2) 0 0 40px 5px,
          rgba(white, 1) 0 0 0 1px, rgba(rgb(255, 255, 255), 0.66) 0 30px 60px 0,
          inset #333 0 0 0 5px, inset white 0 0 0 6px;
      }
    }
  }

  .card {
    position: relative;
    flex: 0 0 240px;
    width: 240px;
    height: 320px;
    background-color: #333;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(rgb(255, 255, 255), 0.66) 0 30px 60px 5px,
      inset #333 0 0 0 5px, inset rgba(white, 0.5) 0 0 0 6px;
    transition: 1s $returnEasing;
  }

  .card-bg {
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 1s $returnEasing, opacity 5s 1s $returnEasing;
    pointer-events: none;
  }

  .card-info {
    padding: 20px;
    position: absolute;
    width: 100%;
    bottom: 0;
    color: #fff;
    transform: translateY(40%);
    transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

    p {
      opacity: 0;
      text-shadow: rgba(black, 1) 0 2px 3px;
      transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    * {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        transparent 0%,
        rgba(#000, 0.6) 100%
      );
      background-blend-mode: overlay;
      opacity: 0;
      transform: translateY(100%);
      transition: 5s 1s $returnEasing;
    }
  }

  .card-info h1 {
    font-family: "Playfair Display";
    font-size: 30px;
    font-weight: 500;
    text-shadow: rgba(black, 0.5) 0 10px 10px;
  }
}
