.containerLoading {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: showLogo var(--loadingAnimation) linear forwards;
  z-index: 999;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
  }
}

@keyframes showLogo {
  0% {
    opacity: 1;
    display: block;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

/* .placementText {
  position: relative;
  grid-area: 0 / 1 / 2 / 2;
  justify-self: right;
  align-self: right;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(00%, 100%);
}

.text {
  width: 100%;
  height: auto;
  display: inline;
  opacity: 0;
  text-decoration: none;
  color: #193a59;
  font-size: 6em;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: normal;
  font-family: Helvetica;
  animation: showLogoTitle 6.5s linear forwards;

  &._O {
    animation-delay: 0.1s;
  }
  &._L {
    animation-delay: 0.2s;
  }
  &._I {
    animation-delay: 0.3s;
  }
  &._V {
    animation-delay: 0.4s;
  }
  &._E {
    animation-delay: 0.5s;
  }
  &._I2 {
    animation-delay: 0.6s;
  }
  &._R {
    animation-delay: 0.7s;
  }
  &._A {
    animation-delay: 0.8s;
  }
}

@keyframes showLogoTitle {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
 */
