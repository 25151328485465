.centerVcard {
  background: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  animation: showLettersVcard 15s ease-in-out forwards;

  .ringVcard {
    position: absolute;
    width: 399px;
    height: 400px;
    border-radius: 50%;
    animation: ring 2s linear infinite;
  }

  .ringVcard:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }

  .titleTextVcard {
    position: absolute;
    transform: translate(0, -60px);
    color: #737373;
    font-size: 2rem;

    animation-delay: calc(var(--loadingAnimation) + 1s);
    &::before {
      animation-delay: calc(var(--loadingAnimation) + 1.1s);
    }
  }

  .timerTextVcard {
    position: absolute;
    transform: translate(0, 10px);
    color: #737373;
    font-size: 4rem;
    animation: timer 1s ease-in-out infinite;
  }

  .textErrorVcard {
    position: absolute;
    color: #737373;
    transform: translate(0, 90px);
    font-size: 2rem;
  }

  .vcardDownloadTextVcard {
    position: absolute;
    transform: translate(0, 130px);
    color: #737373;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

@media (min-width: 270px) and (max-width: 600px) {
  .centerVcard {
    .ringVcard {
      width: 299px;
      height: 300px;
    }
    .titleTextVcard {
      transform: translate(0, -60px);
      font-size: 1.4rem;
    }
    .timerTextVcard {
      transform: translate(0, 10px);
      font-size: 3rem;
    }
    .textErrorVcard {
      transform: translate(0, 70px);
      font-size: 1.4rem;
    }
    .vcardDownloadTextVcard {
      transform: translate(0, 100px);
      font-size: 1rem;
    }
  }
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #e65c00;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18b201;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0456c8;
  }
}

@keyframes showLettersVcard {
  0% {
    color: black;
  }
  100% {
    color: rgb(255, 255, 255);
  }
}

@keyframes timer {
  50% {
    color: black;
  }
}