.language {
  position: relative;
  z-index: 1000;

  display: flex;
  align-items: center;
  .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0;
    &:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    img {
      height: calc(var(--textSize) * 2em);
    }
  }

  @media (min-width: 270px) and (max-width: 600px) {
    .dropdown-toggle {
      &:after {
        margin-left: 0.1em;
        border-top: 0.19em solid;
        border-right: 0.19em solid transparent;
        border-left: 0.19em solid transparent;
      }
      img {
        height: calc(var(--textSize) * 1.3em);
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1023px) {
    .dropdown-toggle {
      &:after {
        margin-left: 0.2em;
        border-top: 0.2em solid;
        border-right: 0.2em solid transparent;
        border-left: 0.2em solid transparent;
      }
      img {
        height: calc(var(--textSize) * 1.6em);
      }
    }
  }
  /*  @media (min-width: 270px) and (max-width: 1023px) {
    .globeIcon {
      filter: invert(82%) sepia(100%) saturate(0%) hue-rotate(341deg)
        brightness(101%) contrast(104%);
    }
  } */
}
