.home {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;

  header {
    width: 50%;
    height: 100%;
    z-index: 1;
  }

  h1 {
    display: table;
    color: #193a59;
    box-shadow: 0 2px 25px #193a59;
    background-color: #bdd9f3;
    padding: 20px 25px;
    font-size: calc(var(--textSize) * 6em);
    line-height: 1em;
    font-weight: 600;
    letter-spacing: normal;
    font-family: Helvetica;
    margin: 1em 0 0 -25%;
    white-space: nowrap;
  }

   .subTitle {
    display: table;
    margin-left: 2em;
    position: relative;
    color: #aaa;
    font-size: calc(var(--textSize) * 2em);
    line-height: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    animation: showLettersHome 1s linear forwards;
    opacity: 0;
    white-space: nowrap;

    &::before {
      content: attr(data-texteffect);
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      color: #344759;
      border-right: 2px solid rgb(0, 0, 0);
      overflow: hidden;
      animation: fillLettters 3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
      white-space: nowrap;
    }

    &.notLoading {
      &.first {
        margin-top: 2em;
        animation-delay: 1s;
        &::before {
          animation-delay: 1.1s;
        }
      }
      &.second {
        animation-delay: 4s;
        &::before {
          animation-delay: 4.1s;
        }
      }
      &.third {
        animation-delay: 7s;
        &::before {
          animation-delay: 7.1s;
        }
      }
    }
    &.loading {
      &.first {
        margin-top: 2em;
        animation-delay: calc(var(--loadingAnimation) + 1s);
        &::before {
          animation-delay: calc(var(--loadingAnimation) + 1.1s);
        }
      }
      &.second {
        animation-delay: calc(var(--loadingAnimation) + 4s);
        &::before {
          animation-delay: calc(var(--loadingAnimation) + 4.1s);
        }
      }
      &.third {
        animation-delay: calc(var(--loadingAnimation) + 7s);
        &::before {
          animation-delay: calc(var(--loadingAnimation) + 7.1s);
        }
      }
    }
  }

  figure {
    position: relative;
    height: 100%;
  }

  img {
    box-shadow: 4px 9px 13px 1px #193a59;
    display: block;
    margin-left: auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    // border: 50px double #bdd9f3;
  }

  main {
    width: 50%;
    z-index: 2;
  }

  footer {
    z-index: 3;
  }

  @media (min-width: 270px) and (max-width: 600px) {
    align-content: flex-start;

    header {
      width: 100%;
      max-height: calc(100% - 6em - 9em);
    }

    main {
      width: 100%;
    }

    h1 {
      margin: -25px auto 0 auto;
      font-size: calc(var(--textSize) * 2em);
      padding: 10px 12px;
    }

    .scrollArrow {
      display: none;
    }

    .subTitle {
      margin: 0 auto;
      font-size: calc(var(--textSize) * 1em);
    }
  }

  @media (min-width: 601px) and (max-width: 1023px) {
    align-content: flex-start;

    header {
      width: 100%;
      max-height: calc(100% - 12em - 6.5em);
    }

    main {
      width: 100%;
    }

    h1 {
      margin: -52px auto 0 auto;
      font-size: calc(var(--textSize) * 4em);
      padding: 20px 25px;
    }

    .scrollArrow {
      display: none;
    }

    .subTitle {
      margin: 0 auto;
      font-size: calc(var(--textSize) * 2em);
    }
  }
}

@keyframes fillLettters {
  0% {
    width: 0;
  }
  99% {
    border-right: 2px solid rgb(0, 0, 0);
  }
  100% {
    width: 100%;
    border-right: 0px;
  }
}

@keyframes showLettersHome {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.scrollArrow {
  position: absolute;
  bottom: 50px;
  left: 50%;
  z-index: 3;

  transform: translate(-50%, -50%);
  background-color: var(--deepBlue);

  width: 100px;
  height: 100px;

  color: var(--backgroundColor0);
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: translate 0.33s ease-in-out;
  cursor: pointer;

  &:active {
    transform: translate(-50%, -50%) scale(0.9);
  }

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 2px solid var(--scrollArrowColor);
    border-bottom: 2px solid var(--scrollArrowColor);
    transform: rotate(-45deg);
    animation: fadeArrowsInOut 2s infinite;
    opacity: 0;
    box-sizing: border-box;

    &:nth-of-type(1) {
      top: 12%;
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }

    &:nth-of-type(2) {
      top: 32%;
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s;
    }

    &:nth-of-type(3) {
      top: 52%;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
  }
}

@keyframes fadeArrowsInOut {
  0% {
    opacity: 0.2;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
