.button {
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: all 333ms cubic-bezier(0.77, 0, 0.175, 1);

  font-size: calc(var(--textSize) * 1.5rem);
  color: black;
  padding: 1.5rem 2.2rem;
  margin: 0 8px;
  text-transform: uppercase;
  text-decoration: none;

  &:active {
    transform: scale(0.9);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    transition: inherit;
    z-index: -1;
  }

  &.from-center {
    &:before {
      top: 0;
      left: 50%;
      height: 100%;
      width: 0;
      border: 1px solid #344759;
      border-left: 0;
      border-right: 0;
    }

    &:after {
      bottom: 0;
      left: 0;
      height: 0;
      width: 100%;
      background: #344759;
    }
  }

  @media (min-width: 270px) and (max-width: 511px) {
    padding: 1rem 1.5rem;
  }

  @media (hover) {
    &:hover {
      transition-delay: 0.2s;
      color: white;

      &:before {
        transition-delay: 0s;
      }
      &:after {
        transition-delay: 0.35s;
      }
    }

    &.from-center {
      &:hover {
        &:before {
          left: 0;
          width: 100%;
        }

        &:after {
          top: 0;
          height: 100%;
        }
      }
    }
  }
}
