svg{
  pointer-events: none;
}
path {
  fill: transparent;
  // transition: fill .3s ease-in-out;

  &.cls-1 {
    fill: #b0a7ec;
  }

  &.cls-2 {
    fill: #8565ca;
  }

  &.cls-3 {
    fill: #8279da;
  }

  &.cls-4 {
    fill: #a098e7;
  }

  &.cls-5 {
    fill: #b2a7ea;
  }

  &.cls-6 {
    fill: #a196e8;
  }

  &.cls-7 {
    fill: #a89fe8;
  }

  &.cls-8 {
    fill: #9b91e3;
  }

  &.cls-9 {
    fill: #a49ae4;
  }

  &.cls-10 {
    fill: #9982d1;
  }

  &.cls-11 {
    fill: #7861c7;
  }

  &.cls-12 {
    fill: #8b6fc8;
  }

  &.cls-13 {
    fill: #8979d1;
  }

  &.cls-14 {
    fill: #a79be4;
  }

  &.cls-15 {
    fill: #9186de;
  }

  &.cls-16 {
    fill: #9085de;
  }

  &.cls-17 {
    fill: #887ad7;
  }

  &.cls-18 {
    fill: #7870d4;
  }

  &.cls-19 {
    fill: #a097e9;
  }

  &.cls-20 {
    fill: #947cc8;
  }

  &.cls-21 {
    fill: #7a57b2;
  }

  &.cls-22 {
    fill: #8c56c2;
  }

  &.cls-23 {
    fill: #a48dd8;
  }

  &.cls-24 {
    fill: #907dd5;
  }

  &.cls-25 {
    fill: #8172cd;
  }

  &.cls-26 {
    fill: #6f60cb;
  }

  &.cls-27 {
    fill: #7e56c5;
  }

  &.cls-28 {
    fill: #a094dd;
  }

  &.cls-29 {
    fill: #6158c3;
  }

  &.cls-30 {
    fill: #8a81dc;
  }

  &.cls-31 {
    fill: #766ac9;
  }

  &.cls-32 {
    fill: #6861cb;
  }

  &.cls-33 {
    fill: #7a70d3;
  }

  &.cls-34 {
    fill: #948ce4;
  }

  &.cls-35 {
    fill: #685cc0;
  }

  &.cls-36 {
    fill: #982e79;
  }

  &.cls-37 {
    fill: #9b0b2d;
  }

  &.cls-38 {
    fill: #960d32;
  }

  &.cls-39 {
    fill: #8b64b4;
  }

  &.cls-40 {
    fill: #a388d4;
  }

  &.cls-41 {
    fill: #a670b7;
  }

  &.cls-42 {
    fill: #7746ab;
  }

  &.cls-43 {
    fill: #894db5;
  }

  &.cls-44 {
    fill: #9744b8;
  }

  &.cls-45 {
    fill: #8b3cad;
  }

  &.cls-46 {
    fill: #6e41b6;
  }

  &.cls-47 {
    fill: #5a47bc;
  }

  &.cls-48 {
    fill: #803fb3;
  }

  &.cls-49 {
    fill: #8144ba;
  }

  &.cls-50 {
    fill: #5137aa;
  }

  &.cls-51 {
    fill: #4340ad;
  }

  &.cls-52 {
    fill: #6358c2;
  }

  &.cls-53 {
    fill: #7269d0;
  }

  &.cls-54 {
    fill: #8678d2;
  }

  &.cls-55 {
    fill: #5955c0;
  }

  &.cls-56 {
    fill: #534ebc;
  }

  &.cls-57 {
    fill: #940b29;
  }

  &.cls-58 {
    fill: #a24c94;
  }

  &.cls-59 {
    fill: #ad60a9;
  }

  &.cls-60 {
    fill: #a21446;
  }

  &.cls-61 {
    fill: #ad1862;
  }

  &.cls-62 {
    fill: #9c1b2d;
  }

  &.cls-63 {
    fill: #aa1333;
  }

  &.cls-64 {
    fill: #a00c2c;
  }

  &.cls-65 {
    fill: #a82844;
  }

  &.cls-66 {
    fill: #a01e47;
  }

  &.cls-67 {
    fill: #990224;
  }

  &.cls-68 {
    fill: #ad4c9e;
  }

  &.cls-69 {
    fill: #9b419d;
  }

  &.cls-70 {
    fill: #916cb8;
  }

  &.cls-71 {
    fill: #8139a9;
  }

  &.cls-72 {
    fill: #9538ab;
  }

  &.cls-73 {
    fill: #8e3fb0;
  }

  &.cls-74 {
    fill: #ad47b7;
  }

  &.cls-75 {
    fill: #a543b1;
  }

  &.cls-76 {
    fill: #7534a7;
  }

  &.cls-77 {
    fill: #6338aa;
  }

  &.cls-78 {
    fill: #802c90;
  }

  &.cls-79 {
    fill: #362c9c;
  }

  &.cls-80 {
    fill: #4c2a91;
  }

  &.cls-81 {
    fill: #594fb3;
  }

  &.cls-82 {
    fill: #34369f;
  }

  &.cls-83 {
    fill: #7967c8;
  }

  &.cls-84 {
    fill: #403faa;
  }

  &.cls-85 {
    fill: #a71e1d;
  }

  &.cls-86 {
    fill: #992c2d;
  }

  &.cls-87 {
    fill: #b5256e;
  }

  &.cls-88 {
    fill: #b42980;
  }

  &.cls-89 {
    fill: #b0174b;
  }

  &.cls-90 {
    fill: #ab2e2d;
  }

  &.cls-91 {
    fill: #a91d21;
  }

  &.cls-92 {
    fill: #aa0f14;
  }

  &.cls-93 {
    fill: #ab1a20;
  }

  &.cls-94 {
    fill: #b43928;
  }

  &.cls-95 {
    fill: #b64ba8;
  }

  &.cls-96 {
    fill: #a5369f;
  }

  &.cls-97 {
    fill: #a63ca7;
  }

  &.cls-98 {
    fill: #983828;
  }

  &.cls-99 {
    fill: #9d37a1;
  }

  &.cls-100 {
    fill: #a93dad;
  }

  &.cls-101 {
    fill: #772d98;
  }

  &.cls-102 {
    fill: #782980;
  }

  &.cls-103 {
    fill: #68267b;
  }

  &.cls-104 {
    fill: #642f91;
  }

  &.cls-105 {
    fill: #8f3695;
  }

  &.cls-106 {
    fill: #3d278b;
  }

  &.cls-107 {
    fill: #4d2467;
  }

  &.cls-108 {
    fill: #2a2679;
  }

  &.cls-109 {
    fill: #1f2786;
  }

  &.cls-110 {
    fill: #313585;
  }

  &.cls-111 {
    fill: #363990;
  }

  &.cls-112 {
    fill: #6c6db3;
  }

  &.cls-113 {
    fill: #5c5da8;
  }

  &.cls-114 {
    fill: #534f9f;
  }

  &.cls-115 {
    fill: #af2714;
  }

  &.cls-116 {
    fill: #ac433f;
  }

  &.cls-117 {
    fill: #a52a84;
  }

  &.cls-118 {
    fill: #ad1f66;
  }

  &.cls-119 {
    fill: #b73b92;
  }

  &.cls-120 {
    fill: #c5499b;
  }

  &.cls-121 {
    fill: #ae3293;
  }

  &.cls-122 {
    fill: #b33d16;
  }

  &.cls-123 {
    fill: #b4561c;
  }

  &.cls-124 {
    fill: #ae3719;
  }

  &.cls-125 {
    fill: #bc5d21;
  }

  &.cls-126 {
    fill: #ad6627;
  }

  &.cls-127 {
    fill: #b23d11;
  }

  &.cls-128 {
    fill: #a23595;
  }

  &.cls-129 {
    fill: #a62b92;
  }

  &.cls-130 {
    fill: #5a4476;
  }

  &.cls-131 {
    fill: #934373;
  }

  &.cls-132 {
    fill: #922a84;
  }

  &.cls-133 {
    fill: #5f216b;
  }

  &.cls-134 {
    fill: #211f62;
  }

  &.cls-135 {
    fill: #453971;
  }

  &.cls-136 {
    fill: #2b3670;
  }

  &.cls-137 {
    fill: #393e81;
  }

  &.cls-138 {
    fill: #4c4a94;
  }

  &.cls-139 {
    fill: #7c7fc0;
  }

  &.cls-140 {
    fill: #6e71a9;
  }

  &.cls-141 {
    fill: #5b629f;
  }

  &.cls-142 {
    fill: #9e2270;
  }

  &.cls-143 {
    fill: #bc7f26;
  }

  &.cls-144 {
    fill: #c58d24;
  }

  &.cls-145 {
    fill: #9e6169;
  }

  &.cls-146 {
    fill: #922b70;
  }

  &.cls-147 {
    fill: #bc8f26;
  }

  &.cls-148 {
    fill: #c59925;
  }

  &.cls-149 {
    fill: #b48626;
  }

  &.cls-150 {
    fill: #5a6189;
  }

  &.cls-151 {
    fill: #344f79;
  }

  &.cls-152 {
    fill: #2b4269;
  }

  &.cls-153 {
    fill: #2a3a61;
  }

  &.cls-154 {
    fill: #475081;
  }

  &.cls-155 {
    fill: #7676b5;
  }

  &.cls-156 {
    fill: #5b718b;
  }

  &.cls-157 {
    fill: #6c718d;
  }

  &.cls-158 {
    fill: #797eb5;
  }

  &.cls-159 {
    fill: #4c6884;
  }

  &.cls-160 {
    fill: #c58f24;
  }

  &.cls-161 {
    fill: #a97b22;
  }

  &.cls-162 {
    fill: #b48266;
  }

  &.cls-163 {
    fill: #9b7c5e;
  }

  &.cls-164 {
    fill: #c89325;
  }

  &.cls-165 {
    fill: #a5796d;
  }

  &.cls-166 {
    fill: #858664;
  }

  &.cls-167 {
    fill: #caa128;
  }

  &.cls-168 {
    fill: #c48621;
  }

  &.cls-169 {
    fill: #b47c20;
  }

  &.cls-170 {
    fill: #4a6c77;
  }

  &.cls-171 {
    fill: #738a54;
  }

  &.cls-172 {
    fill: #385f6a;
  }

  &.cls-173 {
    fill: #273959;
  }

  &.cls-174 {
    fill: #31445a;
  }

  &.cls-175 {
    fill: #334f64;
  }

  &.cls-176 {
    fill: #3c5b6a;
  }

  &.cls-177 {
    fill: #4d646e;
  }

  &.cls-178 {
    fill: #b28722;
  }

  &.cls-179 {
    fill: #ac8923;
  }

  &.cls-180 {
    fill: #977431;
  }

  &.cls-181 {
    fill: #a36e1d;
  }

  &.cls-182 {
    fill: #968b28;
  }

  &.cls-183 {
    fill: #828b4c;
  }

  &.cls-184 {
    fill: #937221;
  }

  &.cls-185 {
    fill: #a47a23;
  }

  &.cls-186 {
    fill: #926025;
  }

  &.cls-187 {
    fill: #cd816f;
  }

  &.cls-188 {
    fill: #bd701f;
  }

  &.cls-189 {
    fill: #a65841;
  }

  &.cls-190 {
    fill: #615a64;
  }

  &.cls-191 {
    fill: #3b605a;
  }

  &.cls-192 {
    fill: #6f6e4a;
  }

  &.cls-193 {
    fill: #333a63;
  }

  &.cls-194 {
    fill: #415144;
  }

  &.cls-195 {
    fill: #4a523c;
  }

  &.cls-196 {
    fill: #af9b27;
  }

  &.cls-197 {
    fill: #928622;
  }

  &.cls-198 {
    fill: #9b812a;
  }

  &.cls-199 {
    fill: #925d1b;
  }

  &.cls-200 {
    fill: #8b7323;
  }

  &.cls-201 {
    fill: #7c7b3e;
  }

  &.cls-202 {
    fill: #83501a;
  }

  &.cls-203 {
    fill: #864f35;
  }

  &.cls-204 {
    fill: #bb6a83;
  }

  &.cls-205 {
    fill: #cca778;
  }

  &.cls-206 {
    fill: #c2852f;
  }

  &.cls-207 {
    fill: #ce9e2f;
  }

  &.cls-208 {
    fill: #bd817b;
  }

  &.cls-209 {
    fill: #b16f2c;
  }

  &.cls-210 {
    fill: #8b4b51;
  }

  &.cls-211 {
    fill: #a15d64;
  }

  &.cls-212 {
    fill: #be7145;
  }

  &.cls-213 {
    fill: #4e5b58;
  }

  &.cls-214 {
    fill: #6c6a30;
  }

  &.cls-215 {
    fill: #665e39;
  }

  &.cls-216 {
    fill: #544717;
  }

  &.cls-217 {
    fill: #928e27;
  }

  &.cls-218 {
    fill: #838424;
  }

  &.cls-219 {
    fill: #8e7428;
  }

  &.cls-220 {
    fill: #a39628;
  }

  &.cls-221 {
    fill: #8e683c;
  }

  &.cls-222 {
    fill: #c58d8e;
  }

  &.cls-223 {
    fill: #c0a641;
  }

  &.cls-224 {
    fill: #c89c90;
  }

  &.cls-225 {
    fill: #b28927;
  }

  &.cls-226 {
    fill: #a89326;
  }

  &.cls-227 {
    fill: #777524;
  }

  &.cls-228 {
    fill: #6d5f1d;
  }

  &.cls-229 {
    fill: #7e681e;
  }

  &.cls-230 {
    fill: #63531b;
  }

  &.cls-231 {
    fill: #4e3811;
  }

  &.cls-232 {
    fill: #9c8225;
  }

  &.cls-233 {
    fill: #8a7f25;
  }

  &.cls-234 {
    fill: #9b8022;
  }

  &.cls-235 {
    fill: #8f721f;
  }

  &.cls-236 {
    fill: #8647ba;
  }

  &.cls-237 {
    fill: #9050c4;
  }

  &.cls-238 {
    fill: #983aab;
  }

  &.cls-239 {
    fill: #9e44b4;
  }
}