@import url("https://fonts.googleapis.com/css?family=Mukta:700");

$bg: #ffffff;
$white: #ffffff;
$black: #193a59;

@mixin transition(
  $property: all,
  $duration: 0.45s,
  $ease: cubic-bezier(0.65, 0, 0.076, 1)
) {
  transition: $property $duration $ease;
}

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

.buttonCV {
  position: relative;
  display: inline-block;
  z-index: 5;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  animation: showCvButton 2s linear forwards;
  opacity: 0;

  &.notLoading {
    animation-delay: 1s;
    &::before {
      animation-delay: 1.1s;
    }
  }
  &.loading {
    animation-delay: calc(var(--loadingAnimation) + 1s);
    &::before {
      animation-delay: calc(var(--loadingAnimation) + 1.1s);
    }
  }

  &.learn-more {
    width: 12rem;
    height: auto;
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      box-shadow: 0 2px 25px $black;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $black;
      border-radius: 1.625rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &::before {
            position: absolute;
            content: "";
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .buttonCV-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $white;
      font-weight: 700;
      line-height: 1.9;
      font-size: calc(var(--textSize) * 0.8em);
      text-align: center;
      text-transform: uppercase;
      opacity: 0;
    }
  }
  &:hover {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
          background: $white;
          transform: translate(1rem, 0);
        }
      }
    }
    .buttonCV-text {
      color: $white;
      opacity: 1;
    }
  }
}

@keyframes showCvButton {
  0% {
    opacity: 0.1;
  }
  50% {
    cursor: pointer;
  }
  100% {
    opacity: 1;
    cursor: pointer;
  }
}
